<template>
    <LandingNavbar />
    <div class="container mt-5">
        <div class="row">
            <h2 class=" fs-1 text-center mt-5 fw-bold">Investors</h2>

            <div class="col-12 mt-5">
                <div class="col-12 col-xl-8 mx-auto">
                    <p class="fs-5 fw-bold text-center my-4">
                        Are you interested in becoming a partner with Dataspike.one ?
                    </p>

                    <p class="fs-5 fw-bold my-4">
                        Are you looking to invest in Dataspike.one? We are always open to working with investors who are interested in contributing their money and expertise to our platform and mission. Our platform offers a wide range of opportunities for investors to make the most of their investments.
                    </p>

                    <p class="fs-5 fw-bold my-4">
                        We have a passionate, highly-skilled team of data scientists, software engineers and customer service professionals who are dedicated to providing our customers with the best possible experience. Our team is committed to delivering the highest quality data products and services and are constantly striving to improve our products and services to ensure that our customers get the best results.
                    </p>

                    <p class="fs-5 fw-bold my-4">
                        dataspike.one is an innovative and rapidly growing data platform that offers investors the opportunity to invest in an exciting and fast-developing sector. Our platform provides investors with access to the data they need to make smart decisions and feel sure of their investments.
                    </p>

                    <p class="fs-5 fw-bold my-4">
                        We are looking for experienced, dedicated investors who are interested in helping to shape the future of the data industry. Our investors will benefit from access to the latest data and analytics tools, as well as bespoke solutions tailored to their needs.

                    </p>

                    <p class="fs-5 fw-bold my-4">
                        If you are interested in becoming an investor with dataspike.one, please get in touch with us. Our team of data scientists, software engineers and customer service professionals are available to discuss the possibilities and help you find the best investment for your business.
                    </p>


                    <div class="col-6 mx-auto my-5">
                        <a href="mailto:contact@dataspike.one" class="btn btn-primary btn-lg d-block">Contact Us</a>
                    </div>
                </div>

                <div class="col-12 col-xl-4 mx-auto">
                    <img src="https://dataspike.fra1.digitaloceanspaces.com/assets%2Fillustrations%2Fundraw_business_deal_re_up4u.svg" class="img-fluid" alt="Partners">
                </div>

            </div>
        </div>
    </div>
    <LandingFooter />
</template>

<script>

import LandingNavbar from '../../components/landing/navbar.vue';
import LandingFooter from '../../components/landing/footer.vue';

export default {
    name: 'Investors',
    components: {
        LandingNavbar,
        LandingFooter
    },
    data() {
        return {
            
        }
    },
    methods: {
        //
    },
    mounted() {

    }
}
</script>

<style >
body{
    background-image: radial-gradient(circle at 1px 1px, rgb(0 0 0 / 10%) 1px, transparent 0) !important;
    background-size: 50px 30px !important;
}


</style>