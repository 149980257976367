<template>
  <!-- if route name in sigin or signup -->
  <div v-if="
  $route.name === 'signin' || 
  $route.name === 'signup' || 
  $route.name === 'landing_home' ||
  $route.name === 'landing_about' ||
  $route.name === 'landing_contact' ||
  $route.name === 'landing_pricing' ||
  $route.name === 'landing_how_it_works' ||
  $route.name === 'landing_terms' ||
  $route.name === 'landing_privacy' ||
  $route.name === 'landing_partners' ||
  $route.name === 'landing_investors' ||
  $route.name === 'not-found' 
">
    <router-view></router-view>
  </div>

  <!-- if route name not in sigin or signup -->
  <div v-else>

    <div class="d-flex">
      <sidebar />
      <div class="d-inline-flex flex-fill">
        <div class="col">
          <div class="col p-3 mobile-padding-bottom">
          <router-view></router-view>
        </div>
        </div>
      </div>
  </div>
  </div>
</template>
  
  <script>

    import sidebar from './components/sidebar/sidebar.vue';

    export default {
      name: "App",
      components: {
        sidebar,
      },
      data() {
        return {
        };
      },
    };
  </script>