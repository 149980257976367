<template>
    <div class="container">
    <footer class="pt-4 my-md-5 pt-md-5 border-top">
    <div class="row">
      <div class="col-12 col-md">
        <img src="https://dataspike.fra1.digitaloceanspaces.com/assets/logo/logo-3-transparent.png" width="65" height="65" class="d-block mx-auto" />
        <h4 class="fw-bold text-primary text-center">dataspike.one</h4>
        <small class="d-block mb-3 text-muted text-center">© 2023 - All rights reserved dataspike.one</small>
      </div>
      <div class="col-6 col-md">
        <h5>Navigation</h5>
        <ul class="list-unstyled text-small">
          <li class="mb-1"><router-link class="text-dark text-decoration-none" :to="{ name: 'landing_home' }">Home</router-link></li>
          <li class="mb-1"><a class="text-dark text-decoration-none" href="https://dataspike.medium.com" target="_blank">Blog</a></li>
          <!-- <li class="mb-1"><a class="text-dark text-decoration-none" href="https://rapidapi.com/organization/dataspike" target="_blank">Pricing</a></li> -->
          <li class="mb-1"><router-link class="text-dark text-decoration-none" :to="{ name: 'landing_contact' }">Contact</router-link></li>
        </ul>
      </div>
      <div class="col-6 col-md">
        <h5>Resources</h5>
        <ul class="list-unstyled text-small">
          <li class="mb-1"><router-link class="text-dark text-decoration-none" :to="{ name: 'landing_how_it_works' }">How it works</router-link></li>
        </ul>
      </div>
      <div class="col-6 col-md">
        <h5>Relationships</h5>
        <ul class="list-unstyled text-small">
          <li class="mb-1"><router-link class="text-dark text-decoration-none" :to="{ name: 'landing_investors' }">Investors</router-link></li>
          <li class="mb-1"><router-link class="text-dark text-decoration-none" :to="{ name: 'landing_partners' }">Partners</router-link></li>
        </ul>
      </div>
      <div class="col-6 col-md">
        <h5>Follow</h5>
        <ul class="list-unstyled text-small">
          <li class="mb-1"><a class="text-dark text-decoration-none" href="https://linkedin.com/company/dataspike-one" target="_blank">Linkedin</a></li>
          <li class="mb-1"><a class="text-dark text-decoration-none" href="https://dataspike.medium.com" target="_blank">Medium</a></li>
          <li class="mb-1"><a class="text-dark text-decoration-none" href="https://www.crunchbase.com/organization/dataspike-one" target="_blank">Crunchbase</a></li>
        </ul>
      </div>
      <div class="col-6 col-md">
        <h5>About</h5>
        <ul class="list-unstyled text-small">
          <li class="mb-1"><router-link class="text-dark text-decoration-none" :to="{ name: 'landing_about' }">About Us</router-link></li>
          <li class="mb-1"><router-link class="text-dark text-decoration-none" :to="{ name: 'landing_privacy' }">Privacy Policies</router-link></li>
          <li class="mb-1"><router-link class="text-dark text-decoration-none" :to="{ name: 'landing_terms' }">Terms and Conditions</router-link></li>
        </ul>
      </div>
    </div>
  </footer>
</div>
</template>

<script>

export default {
    name: 'LandingFooter',
    components: {

    },
    data() {
        return {

        }
    }
}

</script>