import {
    createRouter,
    createWebHistory
} from 'vue-router'

// Views aka Pages
// import Dashboard from './pages/dashboard/dashboard.vue'
// import Sigin from './pages/signin/signin.vue'
// import Signup from './pages/signup/signup.vue'

//Analysis
// import Analysis from './pages/analysis/analysis.vue'
// import AnalysisDetail from './pages/analysis/analysis-detail.vue'

// import Companies from './pages/companies/companies.vue'

//Datasets
// import Datasets from './pages/datasets/datasets.vue'
// import DatasetsDetail from './pages/datasets/datasets-detail.vue'

// import Account from './pages/account/account.vue'
// import Subscriptions from './pages/subscriptions/subscriptions.vue'

//Landing
import Home from './pages/landing/home.vue';
import About from './pages/landing/about.vue';
import Contact from './pages/landing/contact.vue';
import Pricing from './pages/landing/pricing.vue';
import HowItWorks from './pages/landing/how-it-works.vue';
import Terms from './pages/landing/terms.vue';
import Privacy from './pages/landing/privacy.vue';
import Partners from './pages/landing/partners.vue';
import Investors from './pages/landing/investors.vue';


// Errors
import Error404 from './pages/errors/404.vue'

// Routes
const routes = [
    {
        path: '/',
        component: Home,
        name: 'landing_home',
        
    },
    {
        path: '/about',
        component: About,
        name: 'landing_about'
    },
    {
        path: '/contact',
        component: Contact,
        name: 'landing_contact'
    },
    {
        path: '/how-it-works',
        component: HowItWorks,
        name: 'landing_how_it_works'
    },
    {
        path: '/terms',
        component: Terms,
        name: 'landing_terms'
    },
    {
        path: '/privacy',
        component: Privacy,
        name: 'landing_privacy'
    },
    {
        path: '/partners',
        component: Partners,
        name: 'landing_partners'
    },
    {
        path: '/investors',
        component: Investors,
        name: 'landing_investors'
    },
    {
        path: '/:pathMatch(.*)*',
        // redirect: "/",
        name: 'not-found',
        component: Error404
    }
];


const unprotectedRoutes = [
    'landing_home',
    'landing_about',
    'landing_contact',
    'landing_pricing',
    'landing_how_it_works',
    'landing_terms',
    'landing_privacy',
    'landing_partners',
    'landing_investors',
    'signup',
    'signin',
    'not-found',
    'reset-password',
]


const subRequiredRoutes = [
    'dashboard',
    'account',
    'analysis',
    'analysis_detail',
    'companies',
    'datasets',
    'datasets_detail',
]

function Protector(to, from, next) {
    if (unprotectedRoutes.includes(to.name)) {
        next()
    } else {
        if (localStorage.getItem('token')) {
            next()
        } else {
            next('/auth/signin')
        }
    }
}


// export Router Object
const router = createRouter({
    routes,
    history: createWebHistory(),
    linkActiveClass: "active", // active class for non-exact links.
    linkExactActiveClass: "active", // active class for *exact* links.

});

router.beforeEach(Protector)

// export router
export default router;
