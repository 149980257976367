<template>
    <LandingNavbar />
    <div class="container mt-5">
        <div class="row">
            <h2 class="fs-1 text-center mt-5 fw-bold">About Us</h2>

            <div class="col-12 col-xl-8 mx-auto my-5">
                <p>At dataspike.one, we have a passionate, highly-skilled team of data scientists, software engineers and customer service professionals. 
                    Our team is dedicated to providing our customers with the best possible experience. 
                </p>
                <p>
                We are committed to delivering the highest quality data products and services and are constantly striving to improve our products and services to ensure that our customers get the best results.
                </p>
                <p>
                    We are passionate about the data and technology that drives our platform, and we have a strong focus on developing user-friendly, intuitive solutions. 
                </p>
                <p>
                    Our team is committed to providing our customers with the best possible experience and ensuring that they have the data they need to make the right decisions. 
                </p>
                <p>
                    As such, we are always looking for ways to improve our platform and provide our customers with the best possible service.
                </p>

            </div>
        </div>
    </div>
    <LandingFooter />
</template>

<script>

import LandingNavbar from '../../components/landing/navbar.vue';
import LandingFooter from '../../components/landing/footer.vue';

export default {
    name: 'About',
    components: {
        LandingNavbar,
        LandingFooter
    },
    data() {
        return {
            
        }
    },
    methods: {
        //
    },
    mounted() {

    }
}
</script>

<style >
body{
    background-image: radial-gradient(circle at 1px 1px, rgb(0 0 0 / 10%) 1px, transparent 0) !important;
    background-size: 50px 30px !important;
}


</style>