<template>

    <!-- if mobile screen show a different navbar else use the sidebar -->
    <div v-if="isMobile()">
      <div class="col-12 fixed-bottom pb-3 pt-3 bg-white" id="mobile-bar">
        <div class="row text-center">
        <div class="col-3">
          <!-- <router-link :to="{ name: 'dashboard' }" class="nav-link mx-auto">
          <font-awesome-icon icon="fa-solid fa-bars-staggered" />
          
        </router-link> -->
        </div>

        <div class="col-3">
          <!-- <router-link :to="{ name: 'companies' }" class="nav-link mx-auto">
            <font-awesome-icon icon="fa-solid fa-building" />
        </router-link> -->
        </div>

        <div class="col-3">
          <!-- <router-link :to="{ name: 'datasets' }" class="nav-link mx-auto">
          <font-awesome-icon icon="fa-solid fa-database"  />
        </router-link> -->
        </div>

        <div class="col-3">
          <!-- <router-link :to="{ name: 'account' }" class="nav-link mx-auto">
          <font-awesome-icon icon="fa-solid fa-user" />
        </router-link> -->
        </div>

      </div>
      </div>
      
    </div>

    <div v-else class="d-inline-flex flex-column flex-shrink-0 p-3 bg-white" style="width: 280px;" id="sidebar">
      <div class="position-fixed d-inline-flex flex-column flex-shrink-0" style="width: 250px; height:97vh;">
      <!-- <router-link :to="{ name: 'dashboard' }" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-primary text-decoration-none">
        <img src="https://dataspike.fra1.digitaloceanspaces.com/assets/logo/logo-3-transparent.png" width="45" height="45" class="" />
        <span class="fs-3">dataspike.one</span>
      </router-link> -->
    <hr class="col-12 mt-3 mb-5 text-primary" >
    <ul class="nav nav-pills flex-column mb-auto">
      <li class="nav-item">
        <!-- <router-link :to="{ name: 'dashboard' }" class="nav-link">
          <font-awesome-icon icon="fa-solid fa-bars-staggered" class="bi pe-none me-2"/>
          <span class="ms-2">Dashboard</span>
        </router-link> -->
      </li>
      <li>
        <!-- <router-link :to="{ name: 'analysis' }" class="nav-link">
            <font-awesome-icon icon="fa-solid fa-square-poll-vertical" class="bi pe-none me-2" />
          <span class="ms-2">Analysis</span>
        </router-link> -->
      </li>
      <li>
        <!-- <router-link :to="{ name: 'companies' }" class="nav-link">
            <font-awesome-icon icon="fa-solid fa-building" class="bi pe-none me-2" />
          <span class="ms-2">Companies</span>
        </router-link> -->
      </li>
      <li>
        <!-- <router-link :to="{ name: 'datasets' }" class="nav-link">
        <font-awesome-icon icon="fa-solid fa-database" class="bi pe-none me-2" />
          <span class="ms-2">Datasets</span>
        </router-link> -->
      </li>
    </ul>
    <hr>
    <div class="dropdown">
      <a href="#" class="d-flex align-items-center link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
        <span class="bg-primary text-white rounded-circle initials-circle me-2">DF</span>
        <strong>Daniel Fat</strong>
      </a>
      <ul class="dropdown-menu text-small shadow">
        <!-- <li><router-link :to="{ name: 'analysis' }" class="dropdown-item">New Analysis</router-link></li>
        <li><router-link :to="{ name: 'subscriptions' }" class="dropdown-item">Subscriptions</router-link></li>
        <li><router-link :to="{ name: 'account' }" class="dropdown-item">Account</router-link></li> -->
        <li><hr class="dropdown-divider"></li>
        
        <!-- <li><router-link :to="{ name: 'signin' }" class="dropdown-item text-dark">Sign Out</router-link></li> -->
      </ul>
    </div>
  </div>
  </div>
    </template>
    
    <script>
    export default {
      name: 'Sidebar',
      methods: {
        isMobile() {
            return window.innerWidth < 768;
          }
      },
      data() {
        return {
          //
        };
      },
    };
    
    
    </script>


