<template>
    <LandingNavbar />
    <div class="container mt-5">
        <div class="row">
            <h2 class=" fs-1 text-center mt-5 fw-bold">Partners</h2>

            <div class="col-12 mt-5">
                <div class="col-12 col-xl-8 mx-auto">
                    <p class="fs-5 fw-bold text-center my-4">
                        Are you interested in becoming a partner with Dataspike.one ?
                    </p>

                    <p class="fs-5 fw-bold my-4">
                        We offer a variety of partnerships that enable businesses to make the most of our powerful global data platform.
                    </p>

                    <p class="fs-5 fw-bold my-4">
                        Our partners benefit from access to the latest data and analytics tools, as well as bespoke solutions tailored to their needs.
                    </p>

                    <p class="fs-5 fw-bold my-4">
                        If you are interested in exploring a partnership with Dataspike.one, please get in touch with us. 
                    </p>

                    <p class="fs-5 fw-bold my-4">
                        Our team of data scientists, software engineers and customer service professionals are available to discuss the possibilities and help you find the best partnership for your business.

                    </p>
                    <div class="col-6 mx-auto my-5">
                        <a href="mailto:contact@dataspike.one" class="btn btn-primary btn-lg d-block">Contact Us</a>
                    </div>
                </div>

                <div class="col-12 col-xl-4 mx-auto">
                    <img src="https://dataspike.fra1.digitaloceanspaces.com/assets%2Fillustrations%2Fundraw_agreement_re_d4dv.svg" class="img-fluid" alt="Partners">
                </div>

            </div>
        </div>
    </div>
    <LandingFooter />
</template>

<script>

import LandingNavbar from '../../components/landing/navbar.vue';
import LandingFooter from '../../components/landing/footer.vue';

export default {
    name: 'Partners',
    components: {
        LandingNavbar,
        LandingFooter
    },
    data() {
        return {
            
        }
    },
    methods: {
        //
    },
    mounted() {

    }
}
</script>

<style >
body{
    background-image: radial-gradient(circle at 1px 1px, rgb(0 0 0 / 10%) 1px, transparent 0) !important;
    background-size: 50px 30px !important;
}


</style>