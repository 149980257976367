<template>
    <LandingNavbar />
    <div class="container mt-5">
        <div class="row">
            <div class="container px-4 pt-5 mt-5">
                <h2 class="pb-2">Ready to unleash the power of data-driven growth? We'd love to hear from you!</h2>
               
               
                <div class="row row-cols-1 row-cols-md-2 align-items-md-start g-5 py-4">
                <div class="d-flex flex-column align-items-start gap-2">
                    <p>
                Whether you have questions about our advanced analytics solutions or want to explore how <b class="text-primary">dataspike.one</b> can elevate your business, our team is here to help. 
                </p>
                    <p>
                    Contact us today and let's embark on a journey towards informed decision-making, strategic insights, and unrivaled success. Don't let your data remain untapped—let's unlock its potential together.
                    </p>
                    <h3 class="fw-bold">Call Us</h3>
                    <p><small class="">Phone: <a href="tel:40731358888" class="text-decoration-none">+40 (731) 358 888</a></small></p>
                    <h3 class="fw-bold mt-3">Email Us</h3>
                    <p><small class="">Email: <a href="mailto:contact@dataspike.one" class="text-decoration-none">contact@dataspike.one</a></small></p>
                    
                </div>
                <div class="row row-cols-1 row-cols-sm-1 g-4">
                    <div id="hubspot-form"></div>
                </div>
                </div>
            </div>

            
        </div>
    </div>
    <LandingFooter />
</template>

<script>

import LandingNavbar from '../../components/landing/navbar.vue';
import LandingFooter from '../../components/landing/footer.vue';

export default {
    name: 'Contact',
    components: {
        LandingNavbar,
        LandingFooter
    },
    data() {
        return {
            
        }
    },
    methods: {
        //
    },
    mounted() {
        hbspt.forms.create({
            region: "eu1",
            portalId: "139597146",
            formId: "d2940b5b-541b-4cac-82d0-1669cf777042",
            target: "#hubspot-form"
        });
    }
}
</script>

<style >
body{
    background-image: radial-gradient(circle at 1px 1px, rgb(0 0 0 / 10%) 1px, transparent 0) !important;
    background-size: 50px 30px !important;
}


</style>