<template>
    <LandingNavbar />
    <div class="container mt-5 py-5">
        <div class="row py-5">

            <div class="col-5">
                <img src="https://dataspike.fra1.digitaloceanspaces.com/assets%2Fillustrations%2Fundraw_page_not_found_re_e9o6.svg" alt="404" class="img-fluid">
            </div>
            <div class="col-7">
                <h1 class="fs-2 fw-bold mt-5">Ups! Something Went Wrong</h1>
                <h2 class="fs-2 fw-bold mt-5">Error 404 - This page does not exist</h2>
            </div>
           

        </div>
    </div>
    <LandingFooter />
</template>

<script>

import LandingNavbar from '../../components/landing/navbar.vue';
import LandingFooter from '../../components/landing/footer.vue';

export default {
    name: 'Error404',
    components: {
        LandingNavbar,
        LandingFooter
    },
    data() {
        return {
            
        }
    },
    methods: {
        //
    },
    mounted() {

    }
}
</script>

<style >
body{
    background-image: radial-gradient(circle at 1px 1px, rgb(0 0 0 / 10%) 1px, transparent 0) !important;
    background-size: 50px 30px !important;
}


</style>