<template>
    <LandingNavbar />
    <div class="container mt-5">
        <div class="row">
            <h2 class=" fs-1 text-center mt-5 fw-bold">Terms and Conditions</h2>
        </div>

        <div class="col-12 my-5">

            <p>The website dataspike.one is administered and operated by GRIDSEARCH S.R.L., a limited liability company, organized and operating in accordance with Romanian legislation, with its registered office located in Revolutiei 1989, Number 69, Bl. A15, apartment 14, city of Alba Iulia, county Alba, registered in the Trade Register under no. J1/931/14.07.2022, having Unique Identification Code 46475997 and Unique European Identifier (EUID) no. ROONRC.J1/931/2022. </p>
            <p>GRIDSEARCH SRL is not a VAT payer. </p>
            <p>Contacts: Email: contact@dataspike.one</p>
            <p>Mailing address: Revolutiei 1989, Number 69, Bl. A15, apartment 14, city of Alba Iulia, county Alba </p>
            <p>Phone: +40 731 358 888</p>

        </div>


    </div>
    <LandingFooter />
</template>

<script>

import LandingNavbar from '../../components/landing/navbar.vue';
import LandingFooter from '../../components/landing/footer.vue';

export default {
    name: 'Terms',
    components: {
        LandingNavbar,
        LandingFooter
    },
    data() {
        return {
            
        }
    },
    methods: {
        //
    },
    mounted() {

    }
}
</script>

<style >
body{
    background-image: radial-gradient(circle at 1px 1px, rgb(0 0 0 / 10%) 1px, transparent 0) !important;
    background-size: 50px 30px !important;
}


</style>