<template>
    <LandingNavbar />
    <div class="container mt-5">
        <div class="row">
            <h2 class=" fs-1 text-center mt-5 fw-bold">How It Works</h2>

            <div class="col-12 col-xl-12 my-5 py-5">
                <div class="row">


                    <div class="col-12 col-xl-6 mt-5 py-5 text-center text-lg-start">
                        <h3 class="fs-3 fw-bold mb-5 ">We are processing data within our Data Factory</h3>
                        <p>
                            We are using cutting edge technology to build ELT(Extract-Load-Transform) pipelines for BigData processing to make sure we deliver the best products for our customers. 
                        </p>
                        <p>
                            We are extracting public data from different sources all around the world right into our Data Factory, where our Data Engineering team is ingesting and processing the data. 
                        </p>
                        <p>
                            Our Data Science team works close with the Data Products managers to create customer tailored products and deliver high quality data to our customers.
                        </p>

                    </div>

                    <div class="col-12 col-xl-6 mt-5 py-5">
                        <img src="https://dataspike.fra1.digitaloceanspaces.com/assets%2Fillustrations%2Fundraw_data_processing_yrrv.svg" alt="" class="img-fluid">
                    </div>


                    <div class="col-12"></div>


                    <div class="col-12 col-xl-6 mt-5 py-5">
                        <img src="https://dataspike.fra1.digitaloceanspaces.com/assets%2Fillustrations%2Fundraw_real_time_analytics_re_yliv.svg" alt="" class="img-fluid">
                    </div>
                    <div class="col-12 col-xl-6 ms-auto mt-5 py-5 text-center text-lg-end">
                        <h3 class="fs-3 fw-bold mb-5 ">You can Explore and Analyse datasets</h3>
                        <p class="fw-bold">
                            We make it simple and straightforward to explore and analyse datasets!
                        </p>

                        <p>Our cutting-edge search capabilities make it fast and effortless to quickly and accurately locate the data you need from any corner of the globe. </p>
                        <p>Our advanced analytics tools provide users with the ability to swiftly visualise and examine the data, so that they can gain insight into trends and patterns in a matter of moments. </p>
                        <p>Furthermore, our powerful analytics technology allows users to draw conclusions and make predictions about the data in an efficient and effective manner.</p>
                    </div>

                    <div class="col-12 col-xl-6 mt-5 py-5 text-center text-lg-start">
                        <h3 class="fs-3 fw-bold mb-5 ">Define a Strategy based on your analysis</h3>

                        <p class="fw-bold">We believe that an effective data strategy is key to making smart decisions and achieving success. </p>
                        <p>We have developed a comprehensive platform that enables users to explore datasets of company financials and conduct advanced analytics. Our platform makes it simple and straightforward to access the data you need and analyse it quickly and accurately. </p>
                    </div>

                    <div class="col-12 col-xl-6 mt-5 py-5">
                        <img src="https://dataspike.fra1.digitaloceanspaces.com/assets%2Fillustrations%2Fundraw_cohort_analysis_stny.svg" alt="" class="img-fluid">
                    </div>


                    <div class="col-12"></div>


                    <div class="col-12 col-xl-6 mt-5 py-5">
                        <img src="https://dataspike.fra1.digitaloceanspaces.com/assets%2Fillustrations%2Fundraw_revenue_re_2bmg.svg" alt="" class="img-fluid">
                    </div>
                    <div class="col-12 col-xl-6 ms-auto mt-5 py-5 text-center text-lg-end">
                        <h3 class="fs-3 fw-bold mb-5 ">Take Data-Driven Decisions</h3>
                        <p class="fw-bold">
                            At Dataspike.one, our platform enables users to take data-driven decisions with confidence.
                        </p>
                           <p>Furthermore, our powerful analytics technology allows users to draw conclusions and make predictions about the data in an efficient and effective manner. 
                            With our platform, you can be sure that you have the data you need to make informed decisions and take data-driven decisions with confidence. </p>
                     
                    </div>



                </div>
            </div>



        </div>
    </div>
    <LandingFooter />
</template>

<script>

import LandingNavbar from '../../components/landing/navbar.vue';
import LandingFooter from '../../components/landing/footer.vue';

export default {
    name: 'HowItWorks',
    metaInfo:{
        title: 'Dataspike - How It Works',
    },
    components: {
        LandingNavbar,
        LandingFooter
    },
    data() {
        return {
            
        }
    },
    methods: {
        //
    },
    mounted() {

    }
}
</script>

<style >
body{
    background-image: radial-gradient(circle at 1px 1px, rgb(0 0 0 / 10%) 1px, transparent 0) !important;
    background-size: 50px 30px !important;
}


</style>