import { h, createApp } from "vue";
import App from "./App.vue";
import * as bootstrap from 'bootstrap';
import router from "./router";
import state from "./state";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// import all icons
import { fas } from '@fortawesome/free-solid-svg-icons';
/* add icons to the library */
library.add(fas)

import VueApexCharts from "vue3-apexcharts";

const app = createApp(App)
            .component('font-awesome-icon', FontAwesomeIcon)
            .use(VueApexCharts)
            .use(bootstrap)
            .use(state)
            .use(router);


app.mount("#app");