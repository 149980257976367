<template>
    <nav class="navbar fixed-top">
    <div class="container">
    
        <router-link class="navbar-brand text-primary" :to="{name: 'landing_home'}">
            <img src="https://dataspike.fra1.digitaloceanspaces.com/assets/logo/logo-3-transparent.png" width="65" height="65" class="d-inline-flex" />
            <h3 class="d-inline-flex">dataspike.one</h3>
        </router-link>

        <button class="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
        <span class="navbar-toggler-icon"></span>
        </button>
        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
        <div class="offcanvas-header">
            <img src="https://dataspike.fra1.digitaloceanspaces.com/assets/logo/logo-3-transparent.png" width="65" height="65" class="d-inline-flex" />
            <h5 class="offcanvas-title fs-bold text-primary" id="offcanvasDarkNavbarLabel">dataspike.one</h5>
            <button type="button" class="btn-close btn-close-dark" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'landing_home'}" @click.native="removeOverflowHidden()">Home</router-link>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="https://dataspike.medium.com" target="_blank" >Blog</a>
            </li>
            <!-- <li class="nav-item">
                <a class="nav-link" href="https://rapidapi.com/organization/dataspike" target="_blank" >Pricing</a>
            </li> -->
            <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'landing_how_it_works'}" @click.native="removeOverflowHidden()">How It Works</router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'landing_about'}" @click.native="removeOverflowHidden()">About</router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'landing_partners'}" @click.native="removeOverflowHidden()">Partners</router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'landing_investors'}" @click.native="removeOverflowHidden()">Investors</router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'landing_contact'}" @click.native="removeOverflowHidden()">Contact</router-link>
            </li>
      
            <!-- <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Account
                </a>
                <ul class="dropdown-menu">
                <li>
                    <router-link class="dropdown-item" :to="{name: 'signin'}" @click.native="removeOverflowHidden()">Login</router-link>
                </li>
                <li>
                    <router-link class="dropdown-item" :to="{name: 'signup'}" @click.native="removeOverflowHidden()">Register</router-link>
                </li>
                </ul>
            </li> -->
            
            </ul>

            <img src="https://dataspike.fra1.digitaloceanspaces.com/assets/logo/logo-3-transparent.png" width="75" height="75" class="d-block mx-auto" />
        </div>
        </div>
    </div>
    </nav>

</template>

<script>
    export default {
        name: 'LandingNavbar',
        components: {

        },
        data() {
            return {

            }
        },
        methods: {
            removeOverflowHidden() {
                // remove style from body and data-bs-overflow and data-bs-padding-right
                // <body style="overflow: hidden; padding-right: 15px;" data-bs-overflow="hidden" data-bs-padding-right="15px">
                document.body.style = '';
                document.body.removeAttribute('data-bs-overflow');
                document.body.removeAttribute('data-bs-padding-right');
            }


        }
    }
</script>

<style>
.navbar-toggler:focus {
    box-shadow: none;
}

</style>